header {
  width: '100%';
  height: 100px;
  border-bottom: 1px solid #ccc;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  align-items: center;
  justify-content: space-around;
}

.search_area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #ccc;
}

.title {
  height: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 17px;
}

.title div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}


.item {
  width: '100%';
  height: 50px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
}

.item:nth-of-type(odd) {
  background-color: #fafafa;
}

.item div {
  text-align: center;
  flex: 1;
}

.unify_font {
  font-size: 17px;
}